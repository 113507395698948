import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { TranslateModule } from '@ngx-translate/core';

import { CookieBannerComponent } from './cookie-banner/cookie-banner';
import { IconComponent } from './icon/icon';
import { SplashScreenComponent } from './splashscreen/splashscreen';
import { EstimathorFormComponent } from './estimathor/estimathor-form/estimathor-form';
import { EstimathorSlideComponent } from './estimathor/estimathor-slide/estimathor-slide';
import { LeBlogPostComponent } from './blog/blog-post/blog-post';
import { LeBlogPostListComponent } from './blog/blog-post-list/blog-post-list';

@NgModule({
	declarations: [
		CookieBannerComponent,
		IconComponent,
		SplashScreenComponent,
		EstimathorFormComponent,
		EstimathorSlideComponent,
		LeBlogPostComponent,
		LeBlogPostListComponent
	],
	entryComponents: [],
	exports: [
		CookieBannerComponent,
		IconComponent,
		SplashScreenComponent,
		EstimathorFormComponent,
		EstimathorSlideComponent,
		LeBlogPostComponent,
		LeBlogPostListComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		// TranslateModule.forChild({
		// 	extend: true,
		// }),
	],
})
export class LeComponentsModule {}
