export const environment = {
	production: true,
	firebaseConfig: {
		apiKey: "AIzaSyDJu-_2skkdZUqessd063LLoWvsZu4lKrc",
		authDomain: "louyan-exodev-presentation.firebaseapp.com",
		projectId: "louyan-exodev-presentation",
		storageBucket: "louyan-exodev-presentation.appspot.com",
		messagingSenderId: "304929375064",
		appId: "1:304929375064:web:900780bd99b6751d091b2d",
		measurementId: "G-ZLCTZRJDM9"
	},
	reCaptchaSiteKey: '6LfrWdAkAAAAAF3e8BkLYzGqkuwyDJt5YH-sfL8U',
};
