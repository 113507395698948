import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SplashscreenProvider } from '@le-library/providers';

@Component({
	selector: 'le-splashscreen',
	templateUrl: './splashscreen.html',
	styleUrls: ['./splashscreen.scss'],
})
export class SplashScreenComponent implements OnInit {
	@ViewChild('splashText', {static: false}) public splashText?: ElementRef;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		public splash: SplashscreenProvider
	) {}

	ngOnInit() {
		if (this.splashText) {
			this.splashText.nativeElement.innerHTML = this.splash.text;
		}
		this._document.getElementsByTagName('body')[0].classList.add('showing-splashscreen');

		setTimeout(() => {
			this._document.getElementsByTagName('body')[0].classList.remove('showing-splashscreen');

			setTimeout(() => {
				this.splash.alreadyShown = true;
			}, 500);
		}, 1500);
	}
}
