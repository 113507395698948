import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { SplashscreenProvider } from '@le-library/providers';
import { SITE_UNDER_CONSTRUCTION } from '@le-library/common/constants';

@Component({
	selector: 'le-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	public initializationComplete = false;
	public isUnderConstruction = SITE_UNDER_CONSTRUCTION;
	public currentUrl = '';

	constructor(
		private _router: Router,
		public splashScreen: SplashscreenProvider
	) {
		this._router.events.pipe(
			filter((event): event is NavigationStart => event instanceof NavigationStart)
		).subscribe((event: NavigationStart) => {
			this.currentUrl = event.url;
		});

		this.initializeApp();
	}

	private initializeApp() {
		this.initializationComplete = true;
	}

	public isOnPage(page: string): boolean {
		return this.currentUrl.includes(page);
	}
}
