import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.html',
  styleUrls: ['./cookie-banner.scss']
})
export class CookieBannerComponent implements OnInit {
	public cookieConsent!: boolean;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		@Inject(PLATFORM_ID) private _platformId: Object,
	) { }

	ngOnInit(): void {
		this.cookieConsent = isPlatformBrowser(this._platformId) ? this.getCookieConsent() : false;
	}

	public getCookieConsent(): boolean {
		return this._document.cookie.split(';').some((item) => item.trim().startsWith('cookies_accepted='));
	}

	public acceptCookies(): void {
		if(!isPlatformBrowser(this._platformId)) {
			return;
		}
		this._document.cookie = "cookies_accepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
		this.cookieConsent = true;
	}
}
