import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';

import { AngularFireModule } from '@angular/fire/compat';
import { initializeApp } from '@angular/fire/app';
import { initializeAppCheck, provideAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';

import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
	SplashscreenProvider,
	BlogProvider,
	ArticleResolver
} from '@le-library/providers';

import { LeComponentsModule } from '@le-library/components/components.module';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

export const app = () => initializeApp(environment.firebaseConfig);
export const appCheck = () => initializeAppCheck(app(), {
	provider: new ReCaptchaV3Provider(environment.reCaptchaSiteKey),
	isTokenAutoRefreshEnabled: true
});

export class TrailingSlashUrlSerializer extends DefaultUrlSerializer {
	serialize(tree: UrlTree): any {
		let path = super.serialize(tree);
		let pathArr = path.split('?');
		pathArr[0] += '/';
		return pathArr.join('?');
	}
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireAnalyticsModule,
		provideAppCheck( () => appCheck()),
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		AppRoutingModule,
		LeComponentsModule,
	],
	providers: [
		ArticleResolver,
		{
			provide: UrlSerializer,
			useClass: TrailingSlashUrlSerializer
		},
		{
			deps: [],
			provide: SplashscreenProvider,
			useClass: SplashscreenProvider,
		},
		{
			deps: [],
			provide: BlogProvider,
			useClass: BlogProvider,
		},
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
