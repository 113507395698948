import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { IBlogPost } from '@le-library/common/globalInterfaces';
import { BlogProvider } from './blog';
import { EmptyError, Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ArticleResolver implements Resolve<any> {
	constructor(
		private _blog: BlogProvider,
		private _router: Router
	) {}

	// Handle not found post
	public resolve(route: ActivatedRouteSnapshot): Observable<IBlogPost | EmptyError> {
		const id = route.params.postId;
		const post = this._blog.getPost(id);

		if(post) {
			return of(post);
		}

		this._router.navigate(['/not-found']);

		return new Observable<EmptyError>();
	}
}
