<!-- <le-menu *ngIf="!isUnderConstruction"></le-menu> -->
<!-- <le-splashscreen *ngIf="isOnHomePage && !splashScreen.alreadyShown"></le-splashscreen> -->
<le-splashscreen *ngIf="
	!isUnderConstruction &&
	!isOnPage('not-found') &&
	!isOnPage('terms-of-use') &&
	!isOnPage('privacy-policy') &&
	!splashScreen.alreadyShown"
></le-splashscreen>

<router-outlet></router-outlet>

<app-cookie-banner></app-cookie-banner>

<footer *ngIf="!isUnderConstruction">
	<p>© 2023 Louyan Exodev - Premium web development services, <a routerLink="/terms-of-use">Terms of use</a> and <a routerLink="/privacy-policy">Privacy policy</a> apply. Thank you for visiting us!</p>
</footer>
