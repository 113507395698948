import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SITE_UNDER_CONSTRUCTION } from '@le-library/common/constants';
import { ArticleResolver } from '@le-library/providers';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/home',
		pathMatch: 'full',
	},
	{
		path: 'home',
		children: [
			{
				path: '',
				loadChildren: () => import('@le-library/pages/home/home.module').then(m => m.HomePageModule)
			},
		]
	},
	{
		path: 'blog',
		children: [
			{
				path: '',
				loadChildren: () => import('@le-library/pages/articles/articles.module').then(m => m.ArticlesPageModule)
			},
			// {
			// 	path: ':postId',
			// 	redirectTo: '/:postId/',
			// 	pathMatch: 'full',
			// },
			{
				path: ':postId',
				// path: ':postId',
				resolve: {
					post: ArticleResolver
				},
				loadChildren: () => import('@le-library/pages/articles/article/article.module').then(m => m.ArticlePageModule)
			}
		]
	},
	{
		path: 'terms-of-use',
		children: [
			{
				path: '',
				loadChildren: () => import('@le-library/pages/terms-of-use/terms-of-use.module').then(m => m.TermsOfUsePageModule)
			},
		]
	},
	{
		path: 'privacy-policy',
		children: [
			{
				path: '',
				loadChildren: () => import('@le-library/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
			},
		]
	},
	{
		path: 'not-found',
		children: [
			{
				path: '',
				loadChildren: () => import('@le-library/pages/errors/not-found/not-found.module').then(m => m.NotFoundPageModule)
			},
		]
	},
	{ path: '**', redirectTo: 'not-found' },
];

if(SITE_UNDER_CONSTRUCTION) {
	routes.splice(0, 1, {
		path: '**',
		redirectTo: '/under-construction/',
		pathMatch: 'full'
	});
	routes.push({
		path: 'under-construction/',
		children: [
			{
				path: '',
				loadChildren: () => import('@le-library/pages/errors/under-construction/under-construction.module').then(m => m.UnderConstructionPageModule)
			},
		]
	});
}

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			paramsInheritanceStrategy: 'always',
			scrollPositionRestoration: 'enabled',
			initialNavigation: 'enabledBlocking'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
