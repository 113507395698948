import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class SplashscreenProvider {
	public alreadyShown = false;

	constructor(@Inject(PLATFORM_ID) private _platformId: Object) {}

	public get text(): string {
		if(
			isPlatformBrowser(this._platformId) &&
			window.location.href.includes('not-found')
		) {
			return 'Oops, you didn\'t find the solution';
		}
		return 'You found the solution';
	}
}
