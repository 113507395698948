import { Injectable } from '@angular/core';
import { IBlogPost } from '@le-library/common/globalInterfaces';

@Injectable({
  providedIn: 'root'
})
export class BlogProvider {
	public selectedTags: string[] = [];
	public filteredPosts: IBlogPost[] = [];
	public searchTerm: string = '';
	public posts: IBlogPost[] = [
		{
			id: 'how-we-use-psychology-to-enhance-user-experience',
			title: 'How We Use Psychology to Enhance User Experience',
			thumbnail: '/assets/blog/thumbnails/brain-concept-art.webp',
			thumbnailAlt: 'A brain with gears turning and a web design overlay',
			tldr: `Web design is more than just pretty colors and cool fonts. It's about creating an online experience that connects with users psychologically. This involves understanding user behavior, applying design-based learning (DBL), and integrating knowledge from various fields. The goal is to create websites that are not only visually appealing but also user-friendly and intuitive.`,
			content: `<h1>The Science Behind Effective Web Design: How We Use Psychology to Enhance User Experience</h1>
			<img loading="lazy" src="./assets/blog/brain-concept-art.webp" alt="Header Image: A brain with gears turning and a web design overlay">
			<p>Hey there, fellow internet explorer! You've just stumbled upon our cozy little digital nook. Today, we're about to embark on a thrilling journey into the captivating realm of web design and psychology. Yep, you heard it right. We're unraveling the science that goes into crafting websites that are not just eye-candy, but also a treat to navigate. So, buckle up, put on your Sherlock Holmes hat, and let's get this show on the road!</p>

			<h2>The Intersection of Web Design and Psychology</h2>
			<p>Web design isn't just about slapping together some pretty colors and snazzy fonts. It's about concocting an online experience that tickles the user's brain in just the right way. It's about getting into the nitty-gritty of how people think, what they crave, and how they interact with the digital world.</p>
			<p>According to a study by Tsai, Chun-Yen and others, web design is a cocktail of interdisciplinary knowledge, and the education and training of designers play a crucial role in the mix. The ultimate goal of web design is to emphasize functions, quality, and a user-centered operation.</p>

			<h2>The Role of Design-Based Learning (DBL) in Web Design</h2>
			<img loading="lazy" src="./assets/blog/side-view-hands-holding-paper.webp" alt="Image: A designer at a desk with a computer, sketching out a web design">
			<p>Design-based learning (DBL) is a teaching model that plunges learners into real-life scenarios to conduct inquiry-based learning activities. It's about using a toolbox of prerequisite skills to learn. In the process, learners not only use tools and materials to solve problems purposefully, but they also need to know what to do, what to think, and how to integrate ideas from different disciplines to create.</p>
			<p>In the realm of web design, DBL involves understanding the user's needs, brainstorming solutions, creating prototypes, and then testing and refining those prototypes based on user feedback. It's a cycle of continuous learning and improvement, all centered around the user's experience.</p>

			<h2>Knowledge Integration in Web Design</h2>
			<p>Knowledge integration is a dynamic process of linking, distinguishing, organizing, and structuring ideas regarding learning content. In the world of web design, this means integrating different sources and levels of knowledge to create a cohesive and user-friendly website.</p>
			<p>For example, when designing a website, we might need to integrate knowledge from various fields such as graphic design (for the visual aesthetics of the site), psychology (to understand how users interact with the site), and computer programming (to actually build the site).</p>

			<h2>Wrapping Up</h2>
			<img loading="lazy" src="./assets/blog/high-angle-laptop-with-wooden-figurine-academic-cap.webp" alt="Image: A ribbon being tied around a completed web design project">
			<p>So, there you have it, folks! The science behind effective web design is a fascinating blend of psychology, design principles, and technical skills. By understanding how users think and interact with digital interfaces, we can create websites that are not only visually appealing but also intuitive and user-friendly.</p>

			<p>Remember, a great website isn't just about looking good – it's about creating a positive user experience that leaves a lasting impression. And that, dear reader, is where the magic happens!</p>

			<p>Until next time, keep exploring, keep learning, and keep creating!</p>

			<p class="source-text"><sup>[1]</sup> Tsai, Chun-Yen et al. "Applying the design-based learning model to foster undergraduates’ web design skills: the role of knowledge integration". 2022. <a href="https://dx.doi.org/10.1186/s41239-021-00308-4" target="_blank">(Link)</a></p>`,
			date: '2023-02-23T12:00:00Z',
			url: '/blog/how-we-use-psychology-to-enhance-user-experience',
			tags: ['UI/UX', 'Design', 'Psychology']
		},
		{
			id: 'estimathorrr-how-it-makes-web-development-estimation-easy',
			title: 'Estimathórrr: How it Makes Web Development Estimation Easy',
			thumbnail: '/assets/blog/thumbnails/estimathor-first-slide.webp',
			thumbnailAlt: 'Estimathórrr tool',
			tldr: `Meet Estimathórrr, our mighty web development time and cost estimation tool that's as easy to use as playing a game of whack-a-mole! It uses advanced algorithms, taking into account various factors like design and content complexity, number of pages, and required functionality, to provide a comprehensive estimate for your project.`,
			content: `<h1>Estimathórrr: How it Makes Web Development Estimation Easy</h1>
			<p>Ever wondered how much it would cost and how long it would take to build your dream website? Well, wonder no more! We've got a tool that's as mighty as Thor's hammer when it comes to estimation. We call it the Estimathórrr! (Yes, with three Rs for extra power!)</p>
			<img loading="lazy" src="./assets/blog/estimathor-intro.webp" alt="Estimathórrr tool">
			<h2>What is the Estimathórrr?</h2>
			<p>The Estimathórrr is our in-house web development estimation tool. It's designed to give you a comprehensive insight into the potential costs of your project before we start collaborating. And the best part? It's as easy to use as playing a game of whack-a-mole!</p>
			<h2>How Does the Estimathórrr Work?</h2>
			<p>The Estimathórrr uses advanced algorithms to estimate the time and cost of your project. These algorithms are based on years of experience, statistical data from projects we've completed and it's reviewed and updated on a monthly basis to improve it's accuracy. The tool takes into consideration various factors such as the feel and style of the design and content, the number of pages, the functionality required, and more.</p>
			<p>But don't worry, you don't need to be a rocket scientist to use the Estimathórrr. The tool is designed to be user-friendly and intuitive. Just input the details of your project and let the Estimathórrr do the heavy lifting!</p>
			<img loading="lazy" src="./assets/blog/estimathor-first-slide.webp" alt="Inputting details into Estimathórrr">
			<h2>Why Use the Estimathórrr?</h2>
			<p>The Estimathórrr is not just a tool, it's your first step towards elevating your online presence. By providing a clear and comprehensive cost estimate, it helps you make informed decisions about your web development project. Plus, it saves you from any surprise costs down the line.</p>
			<p>And let's not forget, it's fun to use! Who said web development has to be all work and no play?</p>
			<h2>Try out the Estimathórrr</h2>
			<p>Ready to take the first step towards your dream website? Give the Estimathórrr a try today! And remember, if you like what you see, you can submit it as an inquiry so we can start elevating your business.</p>
			<p><a class="button-link centered estimathor-link">Try it out!<a/></p>`,
			date: '2023-05-01T12:00:00Z',
			url: '/blog/estimathorrr-how-it-makes-web-development-estimation-easy',
			tags: ['Psychology', 'Estimathórrr', 'Business']
		},
		{
			id: 'the-benefits-of-our-straightforward-approach-to-web-development',
			title: 'The Benefits of Our Straightforward Approach to Web Development',
			thumbnail: '/assets/blog/thumbnails/hide-the-pain-harold.webp',
			thumbnailAlt: 'An image with the Hide the pain Harold meme',
			tldr: `We're a web development team that values clear, straightforward communication and simplicity in our process. We keep you in the loop at all times, ensuring no surprises, just results. Backed by research, our approach leads to better outcomes for your online presence.`,
			content: `<h1>The Benefits of Our Straightforward Approach to Web Development</h1>
			<p>Hey there, curious person! We know you're here because you're looking for a team to elevate your online presence. And we're here to tell you why we're the right choice. But don't worry, we won't bore you with technical jargon(at least not in this post). We're all about keeping things simple and straightforward, just like our approach to web development.</p>
			<img loading="lazy" src="./assets/blog/hide-the-pain-harold.webp" alt="An image with the Hide the pain Harold meme">
			<h2>Clear Communication: Our Secret Sauce</h2>
			<p>With most web development companies, communication is usually the worst part of the process, no up-front estimations, response times through the roof, lack of details, like your hitting invisible walls at each step.</p>
			<p>Ever heard of the saying, "honesty is the best policy"? Well, we've taken that to heart in our work. We believe in clear, straightforward communication. No beating around the bush, no sugar-coating, and definitely no tech-speak that sounds like we're trying to summon a spirit from the netherworld.</p>
			<p>And guess what? Research backs us up on this. A study titled <a href="https://www.nber.org/papers/w23425" target="_blank">"Straight Talkers and Vague Talkers: The Effects of Managerial Style in Earnings Conference Calls"</a> found that clear communication in business settings leads to better outcomes. So, when we say we're going to elevate your online presence, we mean it. No ifs, ands, or buts about it.</p>
			<img loading="lazy" src="./assets/blog/comic-style-person-explaining-with-lightbulb.webp" alt="A comic-style image of a person speaking clearly and another person understanding with a lightbulb above their head.">
			<h2>Our Straightforward Process: No Surprises, Just Results</h2>
			<p>Our process is as straightforward as our communication. We've broken it down into ten easy steps, which you can read about on our website. But here's the gist: we listen to your needs, prepare a contract, analyze your audience, strategize, get your feedback, finalize the design, get coding, present the website to you, and then hand over the project. Simple, right?</p>
			<h2>Why Does This Matter?</h2>
			<p>Well, besides the fact that we're a bunch of hardworking, talented individuals (if we do say so ourselves), our straightforward approach means you always know what you're getting. There's no guesswork involved. We'll send you updates every step of the way and you can step in at any point, and we believe that's the key to a successful project.</p>
			<p>So, are you ready to elevate your online presence with us? We promise it'll be a no-nonsense journey to the top of the digital world.</p>
			<p>Stay tuned for more posts where we dive into the science behind our work and share tips on how to stand out in the digital space. Until then, keep it straightforward!</p>`,
			date: '2023-04-25T12:00:00Z',
			url: '/blog/the-benefits-of-our-straightforward-approach-to-web-development',
			tags: ['Psychology', 'Communication', 'Business']
		},
		{
			id: 'why-simplicity-matters-designing-websites-for-the-younger-generation',
			title: 'Why Simplicity Matters: Designing Websites for the Younger Generation',
			thumbnail: '/assets/blog/thumbnails/young-people-using-technology.webp',
			thumbnailAlt: 'An image of young people using technology',
			tldr: `We explore the power of simplicity in website design for younger audiences. We discuss the appeal of intuitive, user-friendly websites and provide tips for achieving simplicity in design and content. The goal is to create engaging, easy-to-navigate websites that keep visitors coming back for more.`,
			content: `<h1>Why Simplicity Matters: Designing Websites for the Younger Generation</h1>
			<p>Today, we're embarking on a journey into the heart of website design. Our destination? The land of simplicity. Our mission? To discover why simplicity is the secret ingredient in designing websites for the younger generation.</p>
			<img loading="lazy" src="./assets/blog/young-people-using-technology.webp" alt="An image of young people using technology">
			<p>Now, you might be thinking, "Simplicity? But I want my website to have all the bells and whistles!" Well, before you start adding every widget and plugin under the sun, hold onto your hover effects and let's take a moment to consider the beauty of simplicity.</p>
			<h2>The Science of Simplicity</h2>
			<p>Our brains are wired to prefer things that are easy to understand. This is why we're drawn to websites that are clean, simple, and intuitive. This is especially true for younger generations who have grown up with technology. They're used to apps like Instagram and Snapchat that prioritize simplicity and ease of use over complex features.</p>
			<p>But simplicity in website design isn't just about aesthetics. It's also about functionality. A simple website is easy to navigate, making it more likely that users will stay and explore rather than getting frustrated and leaving.</p>
			<h2>Keeping It Simple</h2>
			<p>So how do you keep things simple on your website? Start with the design. A clean, minimalist design not only looks good, but it also makes it easier for users to find what they're looking for. Avoid cluttering your pages with too many elements. Instead, focus on what's most important and make that the center of attention.</p>
			<p>Next, look at your content. Is it easy to understand? If not, it might be time for a rewrite. Remember, the goal is to communicate, not to impress people with your extensive vocabulary. Use clear, concise language and break up your text with headings, bullet points, and images to make it more digestible.</p>
			<img loading="lazy" src="./assets/blog/simple-website-design-example.webp" alt="Simple website design">
			<h2>Embrace the Simplicity</h2>
			<p>Embracing simplicity doesn't mean you have to sacrifice creativity. On the contrary, it challenges you to find innovative ways to communicate your message with fewer elements. And when you get it right, the result is a website that is both beautiful and effective.</p>
			<p>When it comes to designing websites for the younger generation, simplicity is the name of the game. It's not just about making your website look good - it's about creating a user-friendly experience that keeps people coming back for more.</p>
			<p>Until next time, keep it simple and keep it fun!</p>`,
			date: '2023-04-16T12:00:00Z',
			url: '/blog/why-simplicity-matters-designing-websites-for-the-younger-generation',
			tags: ['UI/UX', 'Design', 'Psychology', 'Communication']
		},
		{
			id: 'why-we-choose-to-create-presentation-websites',
			title: 'Why We Choose to Create Presentation Websites',
			thumbnail: '/assets/blog/thumbnails/cup-coffee-or-tea.webp',
			thumbnailAlt: 'An image of coffee or tea',
			tldr: `In this blog post, we delve into why we choose to create presentation websites. We believe in the power of first impressions and user experience, and a website allows you to control the narrative and guide your visitors on a journey. Presentation websites are versatile, adaptable, and accessible 24/7, making them a great tool for businesses of all sizes. Plus, they allow for easy cost and time estimation, providing transparency and efficiency. In the end, a good website is the best business card you can have!`,
			content: `<h1>Why We Choose to Create Presentation Websites</h1>
			<img loading="lazy" src="./assets/blog/cup-coffee-or-tea.webp" alt="Coffee or Tea Image">
			<p>Have you ever wondered why we're so obsessed with presentation websites? Well, grab a cup of coffee (or tea, we don't discriminate), sit back, and let us enlighten you.</p>
			<p>First off, let's get one thing straight. We're not just creating websites; we're crafting digital experiences. And why do we do this? Because we believe in the power of first impressions. In the digital world, your website is your handshake, your business card, and your elevator pitch all rolled into one.</p>
			<h2>It's All About the User Experience, Baby!</h2>
			<p>Now, you might be thinking, "But I can just create a social media profile and call it a day, right?" Well, sure, you could do that. But let's be real, social media is like a crowded party where everyone's shouting to be heard. Your website? That's your own private VIP lounge.</p>
			<p>On your website, you control the narrative. You decide what your visitors see, when they see it, and how they interact with it. It's your chance to guide them on a journey, showing them not just what you do, but why you do it and why they should care.</p>
			<h2>But Why Presentation Websites Specifically?</h2>
			<img loading="lazy" src="./assets/blog/swiss-army-knife.webp" alt="Swiss Army Knife Image">
			<p>Presentation websites are like the Swiss Army knives of the digital world. They're versatile, adaptable, and they look darn good while doing it.</p>
			<p>Whether you're a small business owner, a freelancer, or a multinational corporation, a well-designed presentation website can help you connect with your audience, showcase your products or services, and even drive sales. And the best part? They're accessible 24/7, 365 days a year. Talk about a hard worker!</p>
			<h2>Cost and Time Estimation: The Icing on the Cake</h2>
			<p>Now, let's talk about the cherry on top, or should we say, the icing on the cake. With presentation websites, cost and time estimation becomes a breeze. You see, these types of websites have a clear structure and purpose, which makes it easier for us, the developers, to estimate the resources needed for the project. This means you'll have a clear understanding of the cost and timeline before we even start. No surprises, no hidden fees, just transparency and efficiency.</p>
			<h2>So, What's the Takeaway?</h2>
			<p>In the end, it all comes down to this: we create presentation websites because we believe in the power of storytelling. And in the digital age, your website is one of the most effective tools you have to tell your story. Plus, with the added benefit of easy cost and time estimation, it's a win-win situation for everyone involved.</p>
			<p>So, are you ready to start your digital journey? We're here to help. Just remember to bring the coffee (or tea, again, we don't discriminate).</p>
			<p>Until next time, keep surfing the digital waves and remember - a good website is the best business card you can have!</p>`,
			date: '2023-04-02T12:00:00Z',
			url: '/blog/why-we-choose-to-create-presentation-websites',
			tags: ['Communication', 'Business']
		}
	];

	constructor() {}

	public getPost(id: string): IBlogPost | null {
		return this.posts.find(post => post.id === id) || null;
	}

	// Retrieve posts sorted by date
	public getPosts(numberOfPosts?: number): IBlogPost[] {
		const sortedPosts = this.posts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

		if(!numberOfPosts) {
			return sortedPosts;
		}

		return sortedPosts.slice(0, numberOfPosts);
	}

	public getFilteredPosts(): IBlogPost[] {
		if(this.selectedTags.length <= 0 && this.searchTerm === '') {
			return this.posts;
		} else {
			return this.filteredPosts;
		}
	}

	public getTags(): string[] {
		let allTags: string[] = [];

		this.posts.forEach(post => {
			post.tags.forEach(tag => {
				if(!allTags.includes(tag)) {
					allTags.push(tag);
				}
			});
		});

		return allTags;
	}

	public toggleTag(tag: string): void {
		const index = this.selectedTags.indexOf(tag);

		if (index === -1) {
			this.selectedTags.push(tag);
		} else {
			this.selectedTags.splice(index, 1);
		}

		this.filterPosts();
	}

	public isTagActive(tag: string): boolean {
		return this.selectedTags.includes(tag);
	}

	// Method to search the title, description and content of posts using complex logic
	public searchPosts(): void {
		const searchTerms = this.searchTerm.toLowerCase().split(' ');

		this.filteredPosts = this.filteredPosts.filter(post => {
			const title = post.title.toLowerCase();
			const tldr = post.tldr.toLowerCase();
			const content = post.content.toLowerCase();

			return searchTerms.every(term => {
				return title.includes(term) || tldr.includes(term) || content.includes(term);
			});
		});
	}

	// Method to filter posts by tags and search term
	public filterPosts(): void {
		this.filteredPosts = this.posts.filter(post => {
			return this.selectedTags.every(tag => post.tags.includes(tag));
		});

		if (this.searchTerm !== '') {
			this.searchPosts();
		}
	}
}
